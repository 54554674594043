import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ViewOrder from "./pages/ViewOrder";
import ViewOrderList from "./pages/ViewOrderList";
import CreateOrder from "./pages/CreateOrder";
import InviteNewMember from "./pages/InviteNewMember";
import VerifyTeamMember from "./pages/VerifyTeamMember";
import { account } from "./appwrite/appwriteConfig";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Box, LoadingOverlay, MantineProvider, Modal } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModel,
  loadingFalse,
  loadingTrue,
  login,
  resetPasswordChange,
  setAdmin,
} from "./redux/userAuth";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./pages/ResetPassword";
import { useEffectOnceWhen } from "./hooks/useEffectOnlyOnce";
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.userAuth.isLoading);
  const isAuth = useSelector((state) => state.userAuth.isAuth);
  const modelMessage = useSelector((state) => state.userAuth.modelMessage);
  const modelStatus = useSelector((state) => state.userAuth.modelStatus);
  const validatedUser = useSelector(
    (state) => state.userAuth.resetPasswordPanel
  );
  const location = useLocation();

  const getUser = async () => {
    dispatch(loadingTrue());
    try {
      let user = await account.get();
      dispatch(
        login({ userEmail: user.email, userID: user["$id"], user: user })
      );
      if (!user.emailVerification) {
        dispatch(resetPasswordChange(true));
      }
      if (user.labels.includes("admin")) {
        dispatch(setAdmin(true));
      } else {
        dispatch(setAdmin(false));
      }
    } catch (er) {
      console.log(er);
    } finally {
      dispatch(loadingFalse());
    }
  };

  useEffectOnceWhen(() => {
    getUser();
  }, true);

  return (
    <>
      <MantineProvider
        theme={{
          shadows: {
            xs: "0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.3)",
            sm: "0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.4)",
            md: "0.1875rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4)",
            lg: "0.1875rem 0.1875rem 0.0625rem 0.5rem rgba(0, 0, 0, 0.4)",
            xl: "0.1875rem 0.1875rem 0.0625rem 1rem rgba(0, 0, 0, 0.4)",
          },
        }}>
        <Box pos="relative">
          <Modal
            opened={validatedUser}
            withCloseButton={false}
            title=""
            fullScreen
            transitionProps={{ transition: "fade", duration: 200 }}>
            <ResetPassword />
          </Modal>

          <Header />
          <Modal
            opened={modelStatus}
            onClose={() => dispatch(closeModel())}
            title="Authentication">
            <h3>{modelMessage}</h3>
          </Modal>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/create-order"
              element={
                <PrivateRoute>
                  <CreateOrder />
                </PrivateRoute>
              }
            />
            <Route path="/home" element={<Home />} />
            <Route path="/view-order/:uniqueId" element={<ViewOrder />} />
            <Route
              path="/view-List"
              element={
                <PrivateRoute>
                  <ViewOrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/invite-member"
              element={
                <PrivateRoute>
                  <InviteNewMember />
                </PrivateRoute>
              }
            />
            <Route
              path="/verify-member"
              element={
                <PrivateRoute>
                  <VerifyTeamMember />
                </PrivateRoute>
              }
            />
            <Route path="/*" element={<Home />} />
          </Routes>
          <Footer />
        </Box>
      </MantineProvider>
    </>
  );
}

export default App;
