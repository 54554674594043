import {
  Paper,
  Text,
  Timeline,
  Title,
  Grid,
  Box,
  Group,
  Button,
  Select,
  Modal,
  createStyles,
  TextInput,
  Flex,
  Checkbox,
  FileButton,
} from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getShipmentDeliveryDocument,
  getShipmentDocument,
  getShipmentExtraItem,
  getShipmentHistory,
  getShipmentItem,
  getShipmentPDF,
  getShipmentPDFAWB,
  getStatusList,
  updateShipmentForm,
  updateShipmentHistory,
  uploadDeliveryDocuments,
} from "../appwrite/utilFunctions";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "./Login";
import { toDateLocal } from "../components/utils";
import {
  loadingFalse,
  loginPanelChange,
  setLocationList,
} from "../redux/userAuth";
import { useEffectOnceWhen } from "../hooks/useEffectOnlyOnce";
import { useDidUpdate } from "@mantine/hooks";

const dummyHistory = [
  {
    status: "Started",
    date: "10/10/2023",
    userName: "Kota1",
  },
  {
    status: "Point2",
    date: "11/10/2023",
    userName: "Kota2",
  },
];

// If not Completed or Cancelled will add the Complete options to data dynamically.
const useStyles = createStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  generalDetails: {
    flexDirection: "column",
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "row",
    },
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "row",
    },
  },
  subPaper: {
    width: "40vw ",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "1rem",
    paddingTop: "1rem",
    marginBottom: "1rem",
    paddingBottom: "1rem",
    [theme.fn.smallerThan("lg")]: {
      width: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  senderDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  productDetails: {
    width: "80%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "1rem",
    paddingTop: "1rem",
    marginBottom: "1rem",
    paddingBottom: "1rem",
  },
  productDetailsSelect: {
    width: "250px",
  },
  productSets: {
    flexDirection: "row",
    width: "100vw",
    alignItems: "center",
    justifyContent: "center",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "column",
    },
  },
  productSet: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "17vw",
    padding: "2em",
    marginTop: "2em",
    marginLeft: "1em",
    marginRight: "1em",
    [theme.fn.smallerThan("lg")]: {
      width: "65%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  uploadButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    padding: "1rem",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "column",
    },
  },
  submitButton: {
    justifyContent: "space-between",
    marginTop: "2rem",
    marginLeft: "3rem",
    display: "flex",
    width: "75vw",
    alignItems: "center",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "column",
    },
  },
}));

const sizeDropDown = [
  { label: "Size S", value: "small" },
  { label: "Size M", value: "medium" },
  { label: "Size L", value: "large" },
  { label: "Size XL", value: "extraL" },
];

const makananDD = [
  { label: "Sembako", name: "sembako" },
  { label: "Biskuit", name: "biskuit" },
  { label: "Milo", name: "milo" },
  { label: "Susu", name: "susu" },
  { label: "Tepung", name: "tepung" },
  { label: "Snack", name: "snack" },
  { label: "Roti", name: "roti" },
];
const pakaianDD = [
  { label: "Baju", name: "baju" },
  { label: "Celana", name: "celana" },
  { label: "Kain", name: "kain" },
  { label: "Selimut", name: "selimut" },
  { label: "Langsir", name: "langsir" },
  { label: "Cadar", name: "cadar" },
  { label: "Sepatu", name: "sepatu" },
];
const alatDapurDD = [
  { label: "Beriuk", name: "beriuk" },
  { label: "Kompor", name: "kompor" },
  { label: "Piring", name: "piring" },
  { label: "Sendok", name: "sendok" },
  { label: "Panci", name: "panci" },
  { label: "Mangkok", name: "mangkok" },
  { label: "Rice Cooker", name: "rcooker" },
];
const barangRumah = [
  { label: "Alat Tukang", name: "alatTukang" },
  { label: "Sabun", name: "sabun" },
  { label: "Shampoo", name: "shampoo" },
  { label: "Odol", name: "odol" },
  { label: "Hand Body", name: "handBody" },
  { label: "Tas", name: "tas" },
  { label: "Bonkea", name: "bonkea" },
];
const elektronik = [
  { label: "TV", name: "tv" },
  { label: "Kulkas", name: "kulkas" },
  { label: "Radio", name: "radio" },
  { label: "VCD Player", name: "vcdPlayer" },
  { label: "Mesin Cuci", name: "mesinCuci" },
  { label: "Kipas", name: "kipas" },
  { label: "Speaker", name: "speaker" },
];
export function HistoryList({
  shipmentId = "",
  userName = false,
  changeNeeded = 0,
}) {
  const [list, setList] = useState([]);
  // const params = useParams();

  const getHistory = async () => {
    try {
      let data = await getShipmentHistory(shipmentId);
      // console.log(data, "the List of History");
      let fetchData = data?.documents.map((item) => {
        return { ...item, date: toDateLocal(item.$createdAt) };
      });
      setList(fetchData);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    if (list.length === 0) {
      getHistory();
    }
  }, []);

  useDidUpdate(() => {
    getHistory();
  }, [changeNeeded]);

  return (
    <Paper>
      <Text variant="text" fz={"xl"} tt={"capitalize"}>
        Order History
      </Text>
      <Timeline
        active={list.length - 1}
        bulletSize={24}
        lineWidth={2}
        mt={"md"}>
        {list.map((item, index) => (
          <Timeline.Item key={index} title={item.status}>
            <Text>{item?.date}</Text>
            {userName && <Text>{item?.userName}</Text>}
          </Timeline.Item>
        ))}
      </Timeline>
    </Paper>
  );
}

const options = [
  {
    value: "STARTED",
    label: "Started",
  },
  {
    value: "REACHED1",
    label: "Reached1",
  },
  {
    value: "REACHED2",
    label: "Reached2",
  },
  {
    value: "REACHED3",
    label: "Reached3",
  },
  {
    value: "REACHED4",
    label: "Reached4",
  },
  {
    value: "REACHED5",
    label: "Reached5",
  },
  {
    value: "REACHED6",
    label: "Reached6",
  },
];

function ViewOrder() {
  const params = useParams();
  const { classes } = useStyles();
  const [extraDetails, setExtraDetails] = React.useState({
    shipmentID: params.uniqueId,
    size: "small",
    extra: false,
    packing: false,
    insurance: false,
    makanan: Array(7).fill(false),
    makananArrayStr: Array(7).fill(""),
    pakaian: Array(7).fill(false),
    pakaianArrayStr: Array(7).fill(""),
    alatDupar: Array(7).fill(false),
    alatDuparArrayStr: Array(7).fill(""),
    barang: Array(7).fill(false),
    barangArrayStr: Array(7).fill(""),
    elektronic: Array(7).fill(false),
    elektronikArrayStr: Array(7).fill(""),
    others: "",
  });
  let newStatusInit = {
    status: "Started",
    userName: "Kota",
    userID: "1442244",
    shipmentId: params.uniqueId,
  };
  const navigate = useNavigate();
  const loginPanel = useSelector((state) => state.userAuth.isAuth);
  const userIsAdmin = useSelector((state) => state.userAuth.isAdmin);
  const userDetails = useSelector((state) => state.userAuth.user);
  const locationList = useSelector((state) => state.userAuth.locationList);
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const [isWarningOpen, setIsWarningOpen] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState(newStatusInit);
  // const [locationList, setLocationList] = React.useState([]);

  const getItem = async () => {
    try {
      let data = await getShipmentItem(params.uniqueId);
      if (data) {
        setData(data);
        setNewStatus((prev) => ({ ...prev, status: data.status }));
        getItemExtra();
      }
    } catch (er) {
      setIsWarningOpen(true);
      dispatch(loginPanelChange(true));
    }
  };
  const getItemExtra = async () => {
    try {
      let data = await getShipmentExtraItem(params.uniqueId);
      if (data) {
        setExtraDetails(data);
      }
    } catch (er) {
      // dispatch(loginPanelChange(true))
      console.log(er, "the Error");
    }
  };
  React.useEffect(() => {
    const getUser = async () => {
      try {
        setNewStatus((prev) => ({
          ...prev,
          userName: userDetails.name,
          userID: userDetails.$id,
        }));
        dispatch(loginPanelChange(false));
      } catch (err) {
        dispatch(loginPanelChange(true));
        console.log(err);
      }
    };
  

    getItem();
    getUser();
  }, [params, loginPanel]);

  const getLocationDetails = async () => {
    let list = await getStatusList().then((res) =>
      res.documents.map((item) => ({ value: item.name, label: item.name }))
    );
    console.log(list, "the complete list");
    dispatch(setLocationList(list));
  };
  useEffectOnceWhen(() => {
    getLocationDetails();
  }, locationList.length === 0);

  const updateHistory = async () => {
    if (newStatus.status !== "") {
      let history = await updateShipmentHistory(uuid(), newStatus);
      let updateForm = await updateShipmentForm(
        { status: newStatus.status },
        data["$id"]
      );
      Promise.all([history, updateForm])
        .then((res) => {
          setOpenSuccessModal(true);
          // dispatch(loadingTrue());
          // navigate("/view-List");
          // navigate(`/view-order/${data.shipmentID}`);
        })
        .catch((er) => console.log(er))
        .finally();
      // console.log(history,updateForm,"the history update")
    }
  };

  const downloadPDF = async () => {
    try {
      let resp = await getShipmentPDF(data?.PDFID);
      window.open(resp.href);
    } catch (er) {
      console.log(er);
    }
  };
  const downloadPDFAWB = async () => {
    try {
      let resp = await getShipmentPDFAWB(data?.PDFID);
      console.log(resp, "the REsp");
      window.open(resp.href);
    } catch (er) {
      console.log(er);
    }
  };

  const downloadDocument = async (item) => {
    try {
      if (item === 3) {
        let resp = await getShipmentDeliveryDocument(params.uniqueId);
        window.open(resp.href);
        return;
      }
      let resp = await getShipmentDocument(
        item === 1 ? data?.document1 : item === 2 ? data?.document2 : null
      );
      window.open(resp.href);
    } catch (er) {
      console.log(er);
    }
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openDeliverySuccessModal, setOpenDeliverySuccessModal] = useState(false);
  const [changeNeeded, setChangeNeeded] = useState(0);
  const [openReasonModal, SetOpenReasonModal] = useState(false);
  const [reason, setReason] = useState("");
  // const forceUpdate = useForceUpdate();

  const timerRef = React.useRef();

  const cancelOrder = async () => {
    SetOpenReasonModal(false);
    let updateForm = await updateShipmentForm(
      { cancelled: true, status: "Cancelled", reason: reason },
      data["$id"]
    );
    let history = await updateShipmentHistory(uuid(), {
      ...newStatus,
      status: "Cancelled",
    });
    Promise.all([history, updateForm])
      .then((res) => {
        setOpenSuccessModal(true);
      })
      .catch(() => {})
      .finally(() => {
        navigate("/view-list");
      });
  };

  const triggerDelivery = async (file) => {
    setOpenDeliverySuccessModal(true);
    try {
      let fileStatus = await uploadDeliveryDocuments(file, params.uniqueId);
      if (fileStatus) {
        let history = await updateShipmentHistory(uuid(), {
          ...newStatus,
          status: "Delivered",
        });
        let updateForm = await updateShipmentForm(
          { status: "Delivered", delivered: true },
          data["$id"]
        );
        Promise.all([history, updateForm]).then((res) => {
          setOpenDeliverySuccessModal(false);
          getItem();
          setChangeNeeded(prev=>prev+1)
          // forceUpdate()
        });
      }
    } catch (er) {
      console.log(er,"the Error")
    }
  };

  // useDidUpdate(()=>{
  //   if(!openDeliverySuccessModal){
      
  //   }
  // },[openDeliverySuccessModal])

  React.useEffect(() => {
    if (openSuccessModal) {
      timerRef.current = setInterval(() => {
        setOpenSuccessModal(false);
        dispatch(loadingFalse());
        setChangeNeeded((prev) => prev + 1);
        // dispatch(loadingFalse());
        // navigate("/view-List");
        // navigate(`/view-order/${data.shipmentID}`);
      }, 3000);
    }
    // "timer" will be undefined again after the next re-render
    return () => clearTimeout(timerRef.current);
  }, [openSuccessModal]);

  // useForceUpdate()

  return (
    <div>
      <Modal
        opened={!loginPanel}
        withCloseButton={false}
        title="Please Login to Continue"
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}>
        {/* Modal content */}
        <Login redirect={false} />
      </Modal>
      <Modal
        opened={isWarningOpen}
        onClose={(e) => console.log(e, "the log")}
        title="Mentioned Item Not Found Please contact the Admin"
        transitionProps={{ transition: "fade", duration: 200 }}>
        {/* Modal content */}
        Data
      </Modal>

      <Modal
        title="Reason for Cancel"
        onClose={() => {
          SetOpenReasonModal(false);
        }}
        opened={openReasonModal}>
        <TextInput
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          // {...form.getInputProps("Id")}
          placeholder="Reason"
          label="Reason"
          variant="filled"
          radius="md"
          size="xs"
        />
        <Group position="apart">
          <Button onClick={() => SetOpenReasonModal(false)}>Close</Button>
          <Button onClick={cancelOrder}>Save</Button>
        </Group>
      </Modal>

      <Modal
        // aut
        // onFocus={true}
        opened={openSuccessModal}
        onClose={(e) => {
          setChangeNeeded((prev) => prev + 1);
          // navigate("/view-List");
          // navigate(`/view-order/${data.shipmentID}`);
        }}
        size={"xs"}
        withCloseButton={false}>
        <p>Successful Updated The Status</p>
      </Modal>
      <Modal
        // aut
        // onFocus={true}
        opened={openDeliverySuccessModal}
        onClose={(e) => {
          setChangeNeeded((prev) => prev + 1);
          // navigate("/view-List");
          // navigate(`/view-order/${data.shipmentID}`);
        }}
        size={"xs"}
        withCloseButton={false}>
        <p>Successful Delivered the Package...</p>
      </Modal>
      {/* Order Details Page */}
      <Title pl="md">Order Details</Title>
      <Grid>
        <Grid.Col span={12}>
          <Box>
            <Paper shadow="md" p="md" withBorder>
              <Group>
                <Text>Order Details</Text>
              </Group>
              <Group position="apart">
                <Text>{data?.shipmentID}</Text>
                {/* <Text>Date: {toDateString(data?.$createdAt)}</Text> */}
                <Text>Date: {toDateLocal(data?.$createdAt)}</Text>
                <Text>tag: {data?.tag}</Text>
                <Text>Amount Paid: {data?.amount}</Text>
              </Group>
              <Group position="apart">
                <Text>Sender Name: {data?.senderName}</Text>
                <Text>Receiver Name: {data?.receiverName}</Text>
                <Text>Status Name: {data?.status}</Text>
                <Text>createdBy: {data?.userName}</Text>
              </Group>
              <Group position="right">
                {data.cancelled && (
                  <Text color="red">Reason: {data?.reason}</Text>
                )}
                {userIsAdmin && (
                  <Button
                    onClick={() => SetOpenReasonModal(true)}
                    disabled={data.cancelled || data.delivered}>
                    Cancel
                  </Button>
                )}
                <Button onClick={downloadPDFAWB}>AWB Receipt</Button>
                <Button
                  onClick={() => downloadDocument(1)}
                  disabled={!data?.document1}>
                  Customer Passport
                </Button>
                <Button
                  onClick={() => downloadDocument(2)}
                  disabled={!data?.document2}>
                  Customer Photo
                </Button>
                <Button onClick={downloadPDF}>PDF receipt</Button>
              </Group>
            </Paper>
          </Box>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={7}>
          <Box mt="sm">
            <Paper p="md" shadow="md">
              <Group position="apart">
                {data.delivered ? (
                  `Status:Delivered`
                ) : (
                  <Select
                    data={locationList}
                    value={newStatus?.status}
                    onChange={(e) =>
                      setNewStatus((prev) => ({ ...prev, status: e }))
                    }
                    label="Status"></Select>
                )}
                <Button
                  disabled={data.cancelled || data.delivered}
                  onClick={updateHistory}>
                  Update
                </Button>
                {data.cancelled ? null : data.delivered ? (
                  <Button onClick={() => downloadDocument(3)}>
                    View Delivered Photo
                  </Button>
                ) : (
                  <FileButton
                    onChange={triggerDelivery}
                    disabled={data.cancelled || data.delivered}>
                    {(props) => (
                      <Button
                        disabled={data.cancelled || data.delivered}
                        {...props}>
                        Delivery Photo
                      </Button>
                    )}
                  </FileButton>
                )}
              </Group>
            </Paper>
          </Box>
        </Grid.Col>
        <Grid.Col span={4}>
          <Box p="md">
            {/* {data.$id !== undefined && ( */}
            <HistoryList
              shipmentId={params.uniqueId}
              userName={true}
              changeNeeded={changeNeeded}
            />
            {/* )} */}
          </Box>
        </Grid.Col>
      </Grid>
      <Grid>
        <div className={classes.submitButton}>
          <Group position="apart">
            <Select
              disabled
              className={classes.productDetailsSelect}
              label="Size"
              value={extraDetails.size}
              data={sizeDropDown}
            />
            <Checkbox disabled checked={extraDetails.extra} label="Extra" />
            <Checkbox
              disabled
              checked={extraDetails.packing}
              label="Packing Kayu"
            />
            <Checkbox
              disabled
              checked={extraDetails.insurance}
              label="Insurans"
            />
          </Group>
        </div>
        {/* Separation of Areas */}
        <Flex className={classes.productSets} direction="row">
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Makanan</Title>
            <Flex rowGap={"lg"} direction="column">
              {makananDD.map((item, index) => (
                <>
                  <Checkbox
                    disabled
                    label={item.label}
                    key={item.name}
                    checked={extraDetails.makanan[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.makanan[index] ? (
                    <TextInput
                      disabled
                      placeholder={item.label}
                      value={extraDetails.makananArrayStr[index]}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              {extraDetails.makananArrayStr.map((item, index) =>
                index > 6 ? (
                  <TextInput
                    disabled
                    value={item}
                    placeholder={item.label}
                    withAsterisk
                  />
                ) : null
              )}
            </Flex>
          </Paper>
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Pakaian</Title>
            <Flex rowGap={"lg"} direction="column">
              {pakaianDD.map((item, index) => (
                <>
                  <Checkbox
                    disabled
                    label={item.label}
                    key={item.name}
                    checked={extraDetails.pakaian[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.pakaian[index] ? (
                    <TextInput
                      disabled
                      placeholder={item.label}
                      value={extraDetails.pakaianArrayStr[index]}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              {extraDetails.pakaianArrayStr.map((item, index) =>
                index > 6 ? (
                  <TextInput
                    disabled
                    value={item}
                    placeholder={item.label}
                    withAsterisk
                  />
                ) : null
              )}
            </Flex>
          </Paper>
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Alat Dupar</Title>
            <Flex rowGap={"lg"} direction="column">
              {alatDapurDD.map((item, index) => (
                <>
                  <Checkbox
                    disabled
                    label={item.label}
                    key={item.name}
                    checked={extraDetails.alatDupar[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.alatDupar[index] ? (
                    <TextInput
                      disabled
                      value={extraDetails.alatDuparArrayStr[index]}
                      placeholder={item.label}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              {extraDetails.alatDuparArrayStr.map((item, index) =>
                index > 6 ? (
                  <TextInput
                    disabled
                    value={item}
                    placeholder={item.label}
                    withAsterisk
                  />
                ) : null
              )}
            </Flex>
          </Paper>

          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Barang Rumah</Title>
            <Flex rowGap={"lg"} direction="column">
              {barangRumah.map((item, index) => (
                <>
                  <Checkbox
                    disabled
                    label={item.label}
                    key={item.name}
                    checked={extraDetails.barang[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.barang[index] ? (
                    <TextInput
                      disabled
                      value={extraDetails.barangArrayStr[index]}
                      placeholder={item.label}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              {extraDetails.barangArrayStr.map((item, index) =>
                index > 6 ? (
                  <TextInput
                    disabled
                    value={item}
                    placeholder={item.label}
                    withAsterisk
                  />
                ) : null
              )}
            </Flex>
          </Paper>
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Elektronik</Title>
            <Flex rowGap={"lg"} direction="column">
              {elektronik.map((item, index) => (
                <>
                  <Checkbox
                    disabled
                    label={item.label}
                    key={item.name}
                    checked={extraDetails.elektronic[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.elektronic[index] ? (
                    <TextInput
                      disabled
                      value={extraDetails.elektronikArrayStr[index]}
                      placeholder={item.label}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              {extraDetails.elektronikArrayStr.map((item, index) =>
                index > 6 ? (
                  <TextInput
                    disabled
                    value={item}
                    placeholder={item.label}
                    withAsterisk
                  />
                ) : null
              )}
            </Flex>
          </Paper>
        </Flex>
      </Grid>
    </div>
  );
}

export default ViewOrder;
