import { useState } from 'react';
import {
  createStyles,
  Header as HeaderApp,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
  Button,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { account } from '../appwrite/appwriteConfig';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userAuth';
import LOGO from "../LOGO.png";

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

let links = [
  { link: "/home", label: "Home" },
  { link: "/create-order", label: "Create Order" },
  { link: "/view-List", label: "Orders List" },
  // { link: "/invite-member", label: "Invite Member" },
]

let memberPath = { link: "/invite-member", label: "Invite Member" };
export function Header() {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const userStatus = useSelector(state=>state.userAuth.user?.status)
  const userIsAdmin = useSelector(state=>state.userAuth.isAdmin)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // React.useEffect(() => {
  //   if (userIsAdmin) {
  //     links.push()
  //   }
  // },[userIsAdmin])

  const logoutFun = async () => {
    dispatch(logout())
    try {
      let resp = await account.deleteSession('current')
      console.log(resp,"the Resp")
    } catch (err) {
      console.log(err,"the err")
    }
    navigate("/login")
  }

  const items = links.map((link) => (
      <>
        <span
          key={link.label}
          // href={link.link}
          className={cx(classes.link, { [classes.linkActive]: active === link.link })}
          onClick={(event) => {
            event.preventDefault();
            setActive(link.link);
            navigate(link.link)
            close();
          }}
          >
          {link.label}
        </span>
      
      </>
  ));

  // return <></>
  return userStatus?(
    <HeaderApp height={HEADER_HEIGHT} mb={20} className={classes.root}>
      <Container className={classes.header}>
        <img style={{width:'6em'}} src={LOGO} alt="horse"/>
        {/* <Text fz="xl" fs="italic">LOGO</Text> */}
        <Group spacing={5} className={classes.links}>
          {items}
          {userIsAdmin &&<span
        className={cx(classes.link, { [classes.linkActive]: active === memberPath.link })}
        onClick={(event) => {
          event.preventDefault();
          setActive(memberPath.link);
          navigate(memberPath.link)
          close();
        }}
      >
        {memberPath.label}
        </span>}
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />

        <Group spacing={5} className={classes.links}>
          <Button onClick={logoutFun}>Logout</Button>
        </Group>
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </HeaderApp>
  ):null;
}
export default Header