import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    isAuth: false,
    userID: null,
    userEmail: null,
    isLoading: false,
    modelMessage: "",
    modelStatus: false,
    isAdmin: false,
    loginPanel: false,
    resetPasswordPanel:false,
    locationList:[],
    membersList:[],
    user: {
        status:false
      }
}

export const counterSlice = createSlice({
  name: 'userAuth',
  initialState: INITIAL_STATE,
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isAuth = true
      state.userID = action.payload.userID
      state.user = action.payload.user
      state.userEmail = action.payload.userEmail
    },
    logout: state => {
      state.isAuth = false;
      state.user = { status: false };
      state.userID = null;
      state.userEmail = null;
      state.isLoading = false;
      state.isAdmin = false;
    },
    loadingTrue: state => {
      state.isLoading = true 
    },
    loadingFalse: state => {
      state.isLoading = false 
    },
    setAdmin:(state,action)=>{
      state.isAdmin = action.payload
    },
    modal: (state,action)=> {
      state.modelStatus = true;
      state.modelMessage = action.payload.message;
    },
    closeModel :(state)=>{
      state.modelStatus = false;
      state.modelMessage = "";
    },
    loginPanelChange :(state,action)=>{
      state.loginPanel = action.payload;
    },
    resetPasswordChange :(state,action)=>{
      state.resetPasswordPanel = action.payload;
    },
    setLocationList:(state,action)=>{
      state.locationList = action.payload
    },
    setMemberList:(state,action)=>{
      state.membersList = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { login,logout,loadingFalse,loadingTrue,modal,closeModel,setAdmin, loginPanelChange, resetPasswordChange,setLocationList,setMemberList} = counterSlice.actions

export default counterSlice.reducer