import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Group,
  Paper,
  Select,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateXLSX, toDateString } from "../components/utils";
import {
  loadingFalse,
  loadingTrue,
  modal,
  setLocationList,
  setMemberList,
} from "../redux/userAuth";
import { getListOfAddressDetailsByListOfIDS, getListOfExtraDetails, getShipmentList, getShipmentListFilter, getStatusList, getUserList } from "../appwrite/utilFunctions";
import { useEffectOnceWhen } from "../hooks/useEffectOnlyOnce";
import { useForm } from "@mantine/form";
import { Pagination } from "@mantine/core";
import { getStringFromList } from "../components/generateExcel";

const useStyles = createStyles((theme) => ({
  mainBox: {
    // width: '100%',
    height: "100%",
    padding: "3rem",
    display: "flex",
    flexDirection: "column",
    margin: "1rem 2rem",
  },
  paper: {
    minWidth: "80vw",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  eachCard: {
    padding: "2rem",
    textAlign: "start",
    margin: "1rem 2rem",
  },
}));

function ViewOrderList() {
  const { classes } = useStyles();
  const userID = useSelector((state) => state.userAuth.userID);
  const userIsAdmin = useSelector((state) => state.userAuth.isAdmin);
  const [dataList, setDataList] = useState([]);
  const locationList = useSelector((state) => state.userAuth.locationList);
  const membersList = useSelector((state) => state.userAuth.membersList);
  const [isSearchResult,setIsSearchResult] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm({
    initialValues: {
      fromDate: null,
      toDate: new Date(),
      Id: null,
      tag: null,
      status: null,
      userID: "",
    },
  });

  const paginationList = async (page) => {
    let resp = await getShipmentList(userID, userIsAdmin, page - 1);
    setDataList(resp?.documents);
  };

  const getList = async () => {
    setIsSearchResult(false)
    try {
      form.setValues({
        fromDate: null,
        toDate: null,
        Id: "",
        tag: "",
        status: "",
        userID: null,
      });
      let resp = await getShipmentList(userID, userIsAdmin, 0);
      setDataList(resp.documents);
    } catch (er) {
      dispatch(modal({ message: "List is Empty", status: true }));
    }
  };
  useEffectOnceWhen(() => {
    getList();
  }, dataList.length === 0);

  const getMembersList = async (e) => {
    // let users = await UpdateUserStatus();
    dispatch(loadingTrue());
    getUserList()
      .then((res) => {
        let data = JSON.parse(res.responseBody);
        dispatch(setMemberList(data.userList.users));
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        dispatch(loadingFalse());
      });
  };

  useEffectOnceWhen(() => {
    getMembersList();
  }, membersList.length === 0 && userIsAdmin);

  const onSearch = async () => {
    setIsSearchResult(true);
    let dateCorrection = new Date(form.values.toDate);
    dateCorrection.setDate(dateCorrection.getDate() + 1);

    let resp = await getShipmentListFilter(userID, userIsAdmin, {
      ...form.values,
      fromDate: form.values.fromDate?.toISOString(),
      toDate: dateCorrection?.toISOString(),
    });
    setDataList(resp.documents);
  };

  const getLocationDetails = async () => {
    let list = await getStatusList().then((res) =>
      res.documents.map((item) => ({ value: item.name, label: item.name }))
    );
    console.log(list, "the complete list");
    dispatch(setLocationList(list));
  };
  useEffectOnceWhen(() => {
    getLocationDetails();
  }, locationList.length === 0);

  // const getStringFromList = (data = {}) => {
  //   const items = [
  //     "alatDuparArrayStr",
  //     "barangArrayStr",
  //     "elektronikArrayStr",
  //     "makananArrayStr",
  //     "pakaianArrayStr",
  //   ];
  //   let list = [];
  //   for (let item of items) {
  //     if (data[item]) {
  //       let newList = [...data[item]];
  //       list = [...list, ...newList?.filter((entry) => entry?.trim() != "")];
  //     }
  //   }
  //   if (list.length === 0) {
  //     return "";
  //   }
  //   return list.join(",");
  // };

  const getDocExcel = async () => {
    dispatch(loadingTrue());
    const items = dataList.map((item) => item.shipmentID);
    const itemsAddress = dataList.reduce((acc, cuu) => {
      acc.push(...[cuu.senderAddress, cuu.receiverAddress]);
      return acc;
    }, []);
    let getSubListsShipmentID = [],baseSize=100,mainListShipmentIds = [...items],mainListAddressIDs=[...itemsAddress],getSubListAddress=[];

    while (mainListShipmentIds.length>0){
      getSubListsShipmentID.push(mainListShipmentIds.splice(0, baseSize));
    }
    while (mainListAddressIDs.length>0){
      getSubListAddress.push(mainListAddressIDs.splice(0, baseSize));
    }

    let respExtra =[],respAdd=[];
    for(let i=0;i<getSubListsShipmentID.length;i++){
      let respList = await getListOfExtraDetails(getSubListsShipmentID[i]);
      respExtra = [...respExtra,...respList];
    }
    for(let i=0;i<getSubListAddress.length;i++){
      let respList = await getListOfAddressDetailsByListOfIDS(getSubListAddress[i]);
      respAdd = [...respAdd,...respList];
    }
    
    const respAddObj = respAdd.reduce(
      (acc, key) => ({ ...acc, [key["$id"]]: { ...key, id: key["$id"] } }),
      {}
    );
    const respExtraObj = respExtra.reduce(
      (acc, key) => ({
        ...acc,
        [key["shipmentID"]]: { ...key, id: key["shipmentID"] },
      }),
      {}
    );

    dispatch(loadingFalse());

    const forExcel = dataList
      .filter((item) => !item.cancelled)
      .map((item, index) => ({
        No: index + 1,
        ["No.Box / Marking"]: item?.shipmentID,
        ["NAMA PENGIRIM"]: item?.senderName,
        ["NO PASPORT/ IC PENGIRIM"]:
          respAddObj[item?.senderAddress]?.passportNum,
        ["ALAMAT PENGIRIM"]: respAddObj[item?.senderAddress]?.houseNum,
        ["NO TLP PENGIRIM"]: item?.senderPhone,
        ["NAMA PENERIMA"]: item?.receiverName,
        ["NO PASPORT/ IC PENERIMA"]:
          respAddObj[item?.receiverAddress]?.passportNum,
        ["DUSUN GUU"]: `${respAddObj[item?.receiverAddress]?.houseNum},${
          respAddObj[item?.receiverAddress]?.streetName
        },${respAddObj[item?.receiverAddress]?.areaName},${
          respAddObj[item?.receiverAddress]?.districtName
        },${respAddObj[item?.receiverAddress]?.state}`,
        ["NO TEL PENERIMA"]: `${item?.receiverPhone},${item?.receiverPhone2}`,
        ["KABUPATEN"]: respAddObj[item?.receiverAddress]?.districtName,
        ["PROVINSI"]: `${respAddObj[item?.receiverAddress]?.state},${
          respAddObj[item?.receiverAddress]?.Province
        }`,
        ["JANIS BARANG"]:
          getStringFromList(respExtraObj[item?.shipmentID]) || "",
        ["JAMLAH PAKAGES"]: 1,
        ["SIZE"]: respExtraObj[item?.shipmentID]?.size || "",
        ["Value"]: item?.amount || "",
      }));
      // console.log(forExcel,"the Excel Data")
    generateXLSX(forExcel);
  };

  const navigateToNewTab =(id)=>{
    window.open(`/view-order/${id}`,'_blank')
    // navigate(`/view-order/${id}`)
  }

  return (
    <Box className={classes.mainBox}>
      <Title> Order List</Title>
      <Paper withBorder={true} p={"md"} m={"md"} shadow="md">
        <Group position="apart" mt="md" mb="xs">
          {/* Date Filter */}
          <Group position="apart" mt="md" mb="xs">
            <DateInput
              {...form.getInputProps("fromDate")}
              label="Date From"
              placeholder="Date From"
              variant="filled"
              radius="md"
              size="xs"
              mx="auto"
            />
            <DateInput
              {...form.getInputProps("toDate")}
              label="Date To"
              placeholder="Date To"
              variant="filled"
              radius="md"
              size="xs"
              mx="auto"
            />
            {userIsAdmin && (
              <Select
                {...form.getInputProps("userID")}
                placeholder="Status "
                label="User"
                variant="filled"
                radius="md"
                size="xs"
                data={membersList.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            )}
          </Group>
          <Group>
            {/* ID Filter */}
            <TextInput
              {...form.getInputProps("Id")}
              placeholder="ID Filter"
              label="ID Filter"
              variant="filled"
              radius="md"
              size="xs"
            />
            <TextInput
              {...form.getInputProps("tag")}
              placeholder="Tag"
              label="Tag"
              variant="filled"
              radius="md"
              size="xs"
            />

            <Select
              {...form.getInputProps("status")}
              placeholder="Status "
              label="Status Filter"
              variant="filled"
              radius="md"
              size="xs"
              data={locationList}
            />
          </Group>
        </Group>
        <Group position="left" mt="md" mb="xs">
          <Button onClick={onSearch}>Search</Button>
          <Button onClick={getList}>Reset</Button>
          <Button onClick={getDocExcel}>Excel</Button>
        </Group>
      </Paper>
      {!isSearchResult && <Pagination
        position="center"
        display={"flex"}
        total={20}
        onChange={(value) => paginationList(value)}
      />}
      <Paper
        withBorder={true}
        className={classes.paper}
        p={"md"}
        m={"md"}
        shadow="md">
        {dataList?.map((item) => (
          <Card
            px="xl"
            shadow="sm"
            radius="md"
            className={classes.eachCard}
            withBorder={true}>
            <Card.Section component="div"></Card.Section>
            <Text weight={"550"}> ID: {item.shipmentID}</Text>
            <Group position="apart" mt="md" mb="xs">
              <Text>Date: {toDateString(item["$createdAt"])}</Text>
              {item.cancelled && <Text color="red">Remark:{item?.reason}</Text>}
              <Text>Status:{item.status}</Text>
              <Text>tag:{item.tag}</Text>
            </Group>
            <Group position="apart" mt="md" mb="xs">
              <Text>Sender: {item.senderName}</Text>
              <Text>Receiver: {item.receiverName}</Text>
              <Text>Created By: {item.userName}</Text>
              <Button
                onClick={() => navigateToNewTab(item.shipmentID) }>
                View Details
              </Button>
            </Group>
          </Card>
        ))}
        {dataList.length === 0 ? <div>List is Empty</div> : null}
      </Paper>
    </Box>
  );
}

export default ViewOrderList;
