import React from "react";
import { TextInput, Button, Flex } from "@mantine/core";
function OtherExtraFields({
  shiftIndex = 0,
  updateTxt = () => {},
  valueSet = [],
}) {
  const [items, setItems] = React.useState(valueSet.slice(shiftIndex));
  return (
    <>
      {items?.map((item, index) => (
        <Flex direction="row" align="center" justify="center" gap="sm">
          <TextInput
            value={valueSet[index + shiftIndex]}
            placeholder={`Others`}
            onChange={(e) => updateTxt(e.target.value, index + shiftIndex)}
          />
          <p onClick={() => setItems((prev) => prev.splice(index, 1))}>X</p>
        </Flex>
      ))}
      <Button onClick={() => setItems((prev) => [...prev, ""])}>Others</Button>
    </>
  );
}

export { OtherExtraFields };
