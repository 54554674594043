const Titles = {
  "makanan": ["Sembako", "Biskuit", "Milo", "Susu", "Tepung", "Snack", "Roti"],
  "pakaian": ["Baju", "Celana", "Kain", "Selimut", "Langsir", "Cadar", "Sepatu"],
  "alatDupar": [
    "Beriuk",
    "Kompor",
    "Piring",
    "Sendok",
    "Panci",
    "Mangkok",
    "Rice Cooker",
  ],
  "barang": [
    "Alat Tukang",
    "Sabun",
    "Shampoo",
    "Odol",
    "Hand Body",
    "Tas",
    "Bonkea",
  ],
  "elektronic": [
    "TV",
    "Kulkas",
    "Radio",
    "VCD Player",
    "Mesin Cuci",
    "Kipas",
    "Speaker",
  ],
};

export const getStringFromList = (data = {}) => {
  const items = [
    { title: "alatDupar", str: "alatDuparArrayStr" },
    { title: "barang", str: "barangArrayStr" },
    { title: "elektronic", str: "elektronikArrayStr" },
    { title: "makanan", str: "makananArrayStr" },
    { title: "pakaian", str: "pakaianArrayStr" },
  ];
//   let list = [];
  let SecondList = []
  for (let item of items) {
    if (data[item.str]) {
      let newList = [...data[item.str]];
      newList.forEach((toPrint, index) => {
        if (toPrint.trim()) {
            let titleOfElement = Titles[item.title][index] ?? 'Custom' 
            SecondList = [...SecondList,`${titleOfElement} - ${toPrint}`]
        }
      });
    }
  }
  if (SecondList.length === 0) {
    return "";
  }
  return SecondList.join(", ");
};
