import { Account, Client, Databases, Storage, Teams, Functions, ID } from "appwrite";


const client = new Client();
// const client = new Client();

client.setEndpoint(process.env.REACT_APP_PROJECT_END_POINT)
.setProject(process.env.REACT_APP_PROJECT_ID)


// client.headers={
//     "Access-Control-Allow-Origin":"*",
//     "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Headers, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization",
//     "Access-Control-Allow-Methods":"GET, PUT, POST, DELETE, PATCH, OPTIONS"
// }
export const account = new Account(client);
export const functions = new Functions(client);
export const teams = new Teams(client);
export const storage = new Storage(client);
export const uniqueId = ID.unique();
export const databases = new Databases(client, process.env.REACT_APP_DATABASE_ID)