import { Button, Paper, PasswordInput, Title, createStyles, rem } from "@mantine/core";
import * as YUP from "yup";
import {
  loadingFalse,
  loadingTrue,
  resetPasswordChange,
} from "../redux/userAuth";
import { useDispatch } from "react-redux";
import { updatePasswordOfUser } from "../appwrite/utilFunctions";
import { useForm, yupResolver } from "@mantine/form";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

const validation = YUP.object().shape({
  oldPass: YUP.string().required(),
  pass: YUP.string().required(),
  pass1: YUP.string().required(),
});

function ResetPassword() {
  const form = useForm({
    initialValues: {
      oldPass: "",
      pass: "",
      pass1: "",
    },
    validate: yupResolver(validation),
  });
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const updatePass = async () => {
    form.validate();
    if (form.isValid) {
      try {
        dispatch(loadingTrue());
        let res = await updatePasswordOfUser(
          form.values.pass,
          form.values.oldPass
        );
        if(res){
          dispatch(resetPasswordChange(false));
        }else{
          dispatch(resetPasswordChange(false));
        }
      } catch (er) {
        console.log(er);
      } finally {
        dispatch(loadingFalse());
      }
    }
  };
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Please Reset Password to Continue
        </Title>

        <PasswordInput
          {...form.getInputProps("oldPass")}
          label="Old Password"
          placeholder="Password"
          size="md"
        />
        <PasswordInput
          {...form.getInputProps("pass")}
          label="New Password"
          placeholder="Password"
          size="md"
        />
        <PasswordInput
          {...form.getInputProps("pass1")}
          label="Confirm Password"
          placeholder="Confirm password"
          mt="md"
          size="md"
        />
        <Button fullWidth mt="xl" size="md" onClick={updatePass}>
          Reset Password
        </Button>
      </Paper>
    </div>
  );
}

export default ResetPassword;
