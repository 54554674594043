import React, { Children } from 'react';
import { useSelector } from 'react-redux';
import {Route, useNavigate,Navigate} from 'react-router-dom';

const PrivateRoute = ({ children }) => {

    const userStatus = useSelector(state => state.userAuth.user?.status)
    if (!userStatus) {
        return <Navigate to="/login"  />;
    }
    return children
};

export default PrivateRoute;