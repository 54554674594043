import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
// import Button from '../components/Button';
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Button,
  Title,
  rem,
  Text,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
// import { useForm } from 'react-hook-form';
import { useForm, yupResolver } from "@mantine/form";
import { account } from "../appwrite/appwriteConfig";
import {
  loadingFalse,
  loadingTrue,
  login,
  resetPasswordChange,
  setAdmin,
} from "../redux/userAuth";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  password: Yup.string().required().min(8, "Minimum 8 Char"),
});

function Login({ redirect = true }) {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = React.useState({ status: false, msg: "" });

  const method = useForm({
    initialValues: { email: "", password: "" },
    validate: yupResolver(schema),
  });

  const handleSubmit = async (data) => {
    method.validate();
    if (method.isValid()) {
      const { email, password } = method.values;
      try {
        dispatch(loadingTrue());
        let resp = await account.createEmailSession(email, password);
        if (resp.current) {
          let user = await account.get();
          dispatch(
            login({ userEmail: user.email, userID: user["$id"], user: user })
          );
          if (!user.emailVerification) {
            dispatch(resetPasswordChange(true));
          }
          if (user.labels.includes("admin")) {
            dispatch(setAdmin(true));
          } else {
            dispatch(setAdmin(false));
          }
          if (redirect) {
            navigate("/home");
          }
        }
      } catch (err) {
        if (err.type === 'user_blocked') {
          setError({ status: true, msg: "The current user has been blocked" });
          return;
        }
        setError({ status: true, msg: err.message });
      } finally {
        dispatch(loadingFalse());
      }
    }
  };

  const userStatus = useSelector((state) => state.userAuth.user?.status);

  if (userStatus && redirect) {
    return <Navigate to="/" />;
  }
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Welcome to SP Transport
        </Title>

        <TextInput
          label="Email address"
          placeholder="hello@gmail.com"
          size="md"
          {...method.getInputProps("email")}
        />
        <PasswordInput
          label="Password"
          placeholder="Your password"
          mt="md"
          withAsterisk
          size="md"
          {...method.getInputProps("password")}
        />
        {error.status ? (
          <Text color="red" pt={"md"} align="center">
            {error.msg}
          </Text>
        ) : null}
        <Button
          fullWidth
          mt="xl"
          size="md"
          type="submit"
          onClick={handleSubmit}>
          Login
        </Button>
      </Paper>
    </div>
  );
}

export default Login;
