import { object, string, boolean, number, } from "yup";

export const validationForm = object({
    shipmentID: string().required(),
    userID: string().required(),
    senderAddress:string().uuid(),
    receiverAddress:string().uuid(),
    senderName:string().required(),
    senderPhone:string().required(),
    receiverName:string().required(),
    receiverPhone:string().required(),
    signature:string().uuid(),
    document1:string().uuid(),
    document2:string().uuid(),
    terms: boolean().isTrue(),
    tag: string(),
    size: string().required(),
    extra: boolean(),
    others: string(),
    amount: number().positive().min(1,"Minimum Amount Needed")
})

export const validateAddress = object({
    houseNum:string(),
    streetName:string(),
    areaName:string(),
    districtName:string(),
    state:string(),
    pincode:string(),
})


export const tryCatchValidateFrom = async (value) => {
    let result = null;
    // result = await validationForm.validate(value);
    // console.log(result, "the validation Form")
    try {
        result = await validationForm.validate(value);
        return [true, null]
    } catch (err) {
        // console.log(err.toString(),"the Result")
        // if (result?.inner) {
        //     for (let i = 0; i < result.inner.length; i++) {
        //         obj[result?.inner[i]?.path] = { msg: result?.inner[i]?.message };
        //     }
        //     return [null, ];
        // }
        return [false, err.toString()] 
    }
}

export const tryCatchValidateAddress = async (value) => {
    let result = null;
    // console.log(result,"the validation Address")
    try {
        result = await validateAddress.validate(value);
        return [true, null]
    } catch (err) {
        return [false, err.toString()] 
    }
}