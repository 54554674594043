import React from "react";
// import {useNavigate} from "react-router-dom"
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import {
  Box,
  Button,
  Paper,
  Text,
  TextInput,
  Title,
  createStyles,
  rem,
  Flex,
} from "@mantine/core";
import image3 from "../images/william-william-NndKt2kF1L4-unsplash.jpg";
import image4 from "../images/andy-li-CpsTAUPoScw-unsplash.jpg";
import { HistoryList } from "./ViewOrder";

const useStyles = createStyles((theme) => ({
  card: {
    height: rem(450),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: rem(32),
    marginTop: theme.spacing.xs,
  },

  category: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

function Card({ image, title, category }) {
  const { classes } = useStyles();

  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      sx={{ backgroundImage: `url(${image})` }}
      className={classes.card}>
      <div>
        <Title order={3} className={classes.title}>
          {title}
        </Title>
        <Text className={classes.category} size="xs">
          {category}
        </Text>
      </div>
    </Paper>
  );
}

function Home() {
  const autoplay = React.useRef(Autoplay({ delay: 2000 }));
  const [shippingID, setShippingID] = React.useState("");
  const [mainID, setMainID] = React.useState("");
  const getShippingData = () => {
    setMainID(shippingID);
  };
  return (
    <>
      <Carousel
        withIndicators
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
        ]}
        plugins={[autoplay.current]}
        styles={{
          color: "white",
          height: "100vh",
          backgroundColor: "blueviolet",
          indicator: {
            width: rem(12),
            height: rem(4),
            transition: "width 250ms ease",
            "&[data-active]": {
              width: rem(40),
            },
          },
        }}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        height="100%"
        loop
        sx={{ flex: 1 }}>
        <Carousel.Slide>
          <Card
            image={image3}
            title={"Welcome to SP Shipping"}
            category={"Cargo & Freight"}
          />
        </Carousel.Slide>
        <Carousel.Slide>
          <Card
            image={image4}
            title={"Welcome to SP Shipping"}
            category={"Cargo & Freight"}
          />
        </Carousel.Slide>
      </Carousel>
      <Flex
        mih={50}
        gap="xl"
        mt="md"
        justify="space-around"
        align="center"
        direction="row"
        wrap="wrap">
        <Box>
          <Title order={5}>Get the Information on the Shipping</Title>
          <div style={{ width: "18em", paddingBottom: "1rem" }}>
            <TextInput
              value={shippingID}
              label="Shipping ID"
              onChange={(e) => setShippingID(e.target.value)}
            />
          </div>
          <Button onClick={getShippingData}>Submit</Button>
        </Box>
        <Box>{mainID !== "" && <HistoryList shipmentId={mainID} />}</Box>
      </Flex>
    </>
  );
}

export default Home;
