import React from "react";
import {
  CreateNewUser,
  UpdateUserAdminStatus,
  UpdateUserEmailVerification,
  UpdateUserStatus,
  getUserList,
} from "../appwrite/utilFunctions";
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { loadingFalse, loadingTrue, setMemberList } from "../redux/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnceWhen } from "../hooks/useEffectOnlyOnce";

const dummyMemberList = [
  {
    id: "New",
    name: "Kota",
    email: "k.saikrishna78@gmail.com",
    status: true,
  },
  {
    id: "New2",
    name: "Kota2",
    email: "k.saikrishna79@gmail.com",
    status: true,
  },
];

function InviteNewMember() {
  const [member, setMember] = React.useState({
    name: "",
    email: "",
    password: "12345678",
  });
  const dispatch = useDispatch();
  const membersList = useSelector((state) => state.userAuth.membersList);

  const updateMember = (e) => {
    const { name, value } = e.target;
    setMember((prev) => ({ ...prev, [name]: value }));
  };

  const getList = async (e) => {
    // let users = await UpdateUserStatus();
    dispatch(loadingTrue());
    getUserList()
      .then((res) => {
        let data = JSON.parse(res.responseBody);
       dispatch(setMemberList(data.userList.users));
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        dispatch(loadingFalse());
      });
  };

  const createNewUser = async () => {
    try {
      let newUser = await CreateNewUser(
        member.name,
        member.email,
        member.password
      );
    } catch (er) {
      console.log(er);
    } finally {
      getList();
    }
  };

  const updateUserStatus = async (id, status) => {
    try {
      let resp = await UpdateUserStatus(id, !status);
      console.log(resp);
    } catch (er) {
      console.log(er);
    } finally {
      getList();
    }
  };

  const updateUserVerification = async (item) => {
    try {
      await UpdateUserEmailVerification(item.id, false);
    } catch (er) {
      console.log(er);
    } finally {
      getList();
    }
  };
  const updateAdminStatus = async (id,adminStatus) => {
    try {
      await UpdateUserAdminStatus(id, adminStatus);
    } catch (er) {
      console.log(er);
    } finally {
      getList();
    }
  };

  useEffectOnceWhen(() => {
    getList();
  },membersList);

  return (
    <Grid justify="space-around" align="center">
      <Grid.Col>
        <Grid justify="center">
          <Paper withBorder shadow="md" maw="80%" w="50rem" p="md">
            <Stack direction="column" p="md" justify="center">
              <Text>Create New User</Text>
              <TextInput
                value={member.name}
                type="text"
                name="name"
                label="name"
                onChange={updateMember}
              />
              <TextInput
                type="email"
                value={member.email}
                name="email"
                label="Email"
                onChange={updateMember}
              />
              <TextInput
                type="text"
                name="pass"
                disabled
                value={member.password}
                label="Password"
                onChange={updateMember}
              />
              <Button onClick={createNewUser}>Submit</Button>
            </Stack>
          </Paper>
        </Grid>
        {/* <button onClick={getList}>CAll</button> */}
      </Grid.Col>
      <Grid.Col>
        <Grid justify="center">
          <Paper withBorder shadow="md" maw="90%" w="80rem" p="sm">
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Reset Password</th>
                  <th>Change Status</th>
                  <th>Reset Password</th>
                </tr>
              </thead>
              <tbody>
                {membersList?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item?.status ? "Active" : "Blocked"}</td>
                    <td>{item?.changeStatus ? "Done" : "Pending"}</td>
                    <td>
                      <Button
                        onClick={() => updateUserStatus(item.id, item.status)}>
                        {item.status ? "Block" : "UnBlock"}
                      </Button>
                    </td>
                    {/* <td>
                      <Button
                        onClick={() =>
                          updateAdminStatus(
                            item.id,
                            item.labels.includes("admin")
                          )
                        }>
                        {item.labels.includes("admin")
                          ? "Remove Admin Access"
                          : "Give Admin Access"}
                      </Button>
                    </td> */}
                    <td>
                      <Button onClick={() => updateUserVerification(item)}>
                        Reset
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
          <Text variant="gradient">
            * Reset Password will change password to Static 12345678
          </Text>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

export default InviteNewMember;
