export const toDateLocal = (date) => new Date(date).toLocaleDateString();
export const toDateString = (date) => new Date(date).toDateString();


function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
  
    this.SheetNames = [];
  
    this.Sheets = {};
  }
  
  const download = (url, name) => {
    let a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
  
    window.URL.revokeObjectURL(url);
  };
  
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
  
    const view = new Uint8Array(buf);
  
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  
    return buf;
  }

export const objToString = (data) => {
  return Object.values(data).join("\n");
};

export const b64toFile = async (b64Data, uniqueID) => {
  try {
    let base64Response = await fetch(b64Data);
    let blob = await base64Response.arrayBuffer();
    return new File([blob], uniqueID, { lastModified: new Date().valueOf() });
  } catch (err) {
    console.log(err);
    return new Blob([], { type: "image/png" });
  }

  // const byteCharacters = atob(b64Data);
  // const byteArrays = [];

  // for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //   const slice = byteCharacters.slice(offset, offset + sliceSize);

  //   const byteNumbers = new Array(slice.length);
  //   for (let i = 0; i < slice.length; i++) {
  //     byteNumbers[i] = slice.charCodeAt(i);
  //   }

  //   const byteArray = new Uint8Array(byteNumbers);
  //   byteArrays.push(byteArray);
  // }

  // const blob = new Blob(byteArrays, {type: contentType});
  // return blob;
};

export const generateXLSX = (data) => {
  import("xlsx").then((XLSX) => {
    const wb = new Workbook();
    const ws = XLSX.utils.json_to_sheet(data);

    wb.SheetNames.push("");
    wb.Sheets[""] = ws;

    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    let url = window.URL.createObjectURL(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" })
    );

    download(url, "import.xlsx");
  });
};
