import React, { useState } from "react";
import {
  generatePDFFunction,
  generatePDFShipmentFunction,
  updateShipmentHistory,
  uploadAddress,
  uploadDocuments,
  uploadShipmentExtraForm,
  uploadShipmentForm,
  uploadSignature,
} from "../appwrite/utilFunctions";
import { v4 as uuid } from "uuid";
import {
  Paper,
  TextInput,
  createStyles,
  Select,
  Flex,
  Title,
  Button,
  Checkbox,
  FileButton,
  Text,
  Modal,
} from "@mantine/core";
import {
  tryCatchValidateAddress,
  tryCatchValidateFrom,
} from "../components/validationForCreate";
import { useDispatch, useSelector } from "react-redux";
import { toDateLocal } from "../components/utils";
import { useNavigate } from "react-router-dom";
import { loadingFalse, loadingTrue, modal } from "../redux/userAuth";
import { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import { useDisclosure } from "@mantine/hooks";
import { OtherExtraFields } from "../components/otherExtraFields";
import { uniqueId } from "../appwrite/appwriteConfig";

const useStyles = createStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  generalDetails: {
    flexDirection: "column",
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "row",
    },
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "row",
    },
  },
  subPaper: {
    width: "40vw ",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "1rem",
    paddingTop: "1rem",
    marginBottom: "1rem",
    paddingBottom: "1rem",
    [theme.fn.smallerThan("lg")]: {
      width: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  senderDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  productDetails: {
    width: "80%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "1rem",
    paddingTop: "1rem",
    marginBottom: "1rem",
    paddingBottom: "1rem",
  },
  productDetailsSelect: {
    width: "250px",
  },
  productSets: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "column",
    },
  },
  productSet: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "17vw",
    padding: "2em",
    marginTop: "2em",
    marginLeft: "1em",
    marginRight: "1em",
    [theme.fn.smallerThan("lg")]: {
      width: "65%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  uploadButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    padding: "1rem",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "column",
    },
  },
  submitButton: {
    justifyContent: "space-between",
    marginTop: "2rem",
    display: "flex",
    width: "75vw",
    alignItems: "center",
    [theme.fn.smallerThan("lg")]: {
      flexDirection: "column",
    },
  },
}));

const senderDetails = [
  { label: "Nama", name: "senderName", shipment: true },
  { label: "No IC / Passport", name: "passportNum", shipment: false },
  { label: "Alamat", name: "houseNum", shipment: false },
  { label: "Poskod", name: "pincode", shipment: false },
  { label: "No Telefon", name: "senderPhone", shipment: true },
  // {
  //   label: "No Telefon 2",
  //   name: "senderPhone2",
  //   shipment: true,
  // },
  // { label: "Street", name: "streetName", shipment: false },
  // { label: "Area", name: "areaName", shipment: false },
  // { label: "District", name: "districtName", shipment: false },
  // { label: "State", name: "state", shipment: false },
];

const receiverDetails = [
  { label: "Nama Penerima", name: "receiverName", shipment: true },
  { label: "No passport / ID / KTP ", name: "passportNum", shipment: false },
  { label: "Alamat", name: "houseNum", shipment: false },
  // { label: "Street", name: "streetName", shipment: false },
  { label: "RT/RW", name: "areaName", shipment: false },
  { label: "KEL/DESA", name: "streetName", shipment: false },
  { label: "PROVINSI", name: "Province", shipment: false },
  { label: "KAB", name: "districtName", shipment: false },
  { label: "KEC", name: "state", shipment: false },
  { label: "Poskod", name: "pincode", shipment: false },
  { label: "No Telefon Penerima 1", name: "receiverPhone", shipment: true },
  {
    label: "No telefon Penerima 2",
    name: "receiverPhone2",
    shipment: true,
  },
];

const sizeDropDown = [
  { label: "Size S", value: "small" },
  { label: "Size M", value: "medium" },
  { label: "Size L", value: "large" },
  { label: "Size XL", value: "extraL" },
];

const makananDD = [
  { label: "Sembako", name: "sembako" },
  { label: "Biskuit", name: "biskuit" },
  { label: "Milo", name: "milo" },
  { label: "Susu", name: "susu" },
  { label: "Tepung", name: "tepung" },
  { label: "Snack", name: "snack" },
  { label: "Roti", name: "roti" },
];
const pakaianDD = [
  { label: "Baju", name: "baju" },
  { label: "Celana", name: "celana" },
  { label: "Kain", name: "kain" },
  { label: "Selimut", name: "selimut" },
  { label: "Langsir", name: "langsir" },
  { label: "Cadar", name: "cadar" },
  { label: "Sepatu", name: "sepatu" },
];
const alatDapurDD = [
  { label: "Beriuk", name: "beriuk" },
  { label: "Kompor", name: "kompor" },
  { label: "Piring", name: "piring" },
  { label: "Sendok", name: "sendok" },
  { label: "Panci", name: "panci" },
  { label: "Mangkok", name: "mangkok" },
  { label: "Rice Cooker", name: "rcooker" },
];
const barangRumah = [
  { label: "Alat Tukang", name: "alatTukang" },
  { label: "Sabun", name: "sabun" },
  { label: "Shampoo", name: "shampoo" },
  { label: "Odol", name: "odol" },
  { label: "Hand Body", name: "handBody" },
  { label: "Tas", name: "tas" },
  { label: "Bonkea", name: "bonkea" },
];
const elektronik = [
  { label: "TV", name: "tv" },
  { label: "Kulkas", name: "kulkas" },
  { label: "Radio", name: "radio" },
  { label: "VCD Player", name: "vcdPlayer" },
  { label: "Mesin Cuci", name: "mesinCuci" },
  { label: "Kipas", name: "kipas" },
  { label: "Speaker", name: "speaker" },
];

const toDayDate = toDateLocal(new Date());

const ShipmentIDCreation = () => {
  var currentDate = new Date();
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1;
  var year = currentDate.getFullYear();
  let dateItem = day.toString() + month.toString() + year.toString();
  return `SPC-${dateItem}-${uuid().split("-")[0]}`;
};

function CreateOrder() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const userAuthID = useSelector((state) => state.userAuth.userID);
  const userName = useSelector((state) => state.userAuth.user.name);
  const navigate = useNavigate();
  const signatureRef = useRef();
  const [opened, { close, open }] = useDisclosure(false);
  const [shipmentID, _] = useState(ShipmentIDCreation());
  const [PDFID, _setPDFID] = useState(uuid());
  const [shipmentExtraID] = useState(uuid());
  const [senderAddressID] = useState(uuid());

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const timerRef = useRef();

  const [receiverAddressID] = useState(uuid());

  const [document1ID] = useState(uuid());

  const [document2ID] = useState(uuid());

  const [signatureID] = useState(uuid());

  const [formData, setFormData] = React.useState({
    shipmentID: shipmentID,
    status: "STARTED",
    tag: "STARTED",
    userID: userAuthID,
    senderAddress: senderAddressID,
    senderName: "",
    senderPhone: "",
    receiverAddress: receiverAddressID,
    receiverName: "",
    receiverPhone: "",
    senderPhone2: "",
    receiverPhone2: "",
    signature: signatureID,
    document1: document1ID,
    document2: document2ID,
    terms: false,
    PDFID: shipmentID,
    amount: 0,
    userName: userName,
  });

  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const [senderAddress, setSenderAddress] = React.useState({
    houseNum: "",
    streetName: "",
    areaName: "",
    districtName: "",
    state: "",
    pincode: "",
    passportNum: "",
  });

  const [receiverAddress, setReceiverAddress] = React.useState({
    houseNum: "",
    streetName: "",
    areaName: "",
    districtName: "",
    state: "",
    pincode: "",
    passportNum: "",
    Province: "",
  });

  const [extraDetails, setExtraDetails] = React.useState({
    shipmentID: shipmentID,
    size: "small",
    extra: false,
    packing: false,
    insurance: false,
    wrapping: false,
    makanan: Array(7).fill(false),
    makananArrayStr: Array(7).fill(""),
    pakaian: Array(7).fill(false),
    pakaianArrayStr: Array(7).fill(""),
    alatDupar: Array(7).fill(false),
    alatDuparArrayStr: Array(7).fill(""),
    barang: Array(7).fill(false),
    barangArrayStr: Array(7).fill(""),
    elektronic: Array(7).fill(false),
    elektronikArrayStr: Array(7).fill(""),
    others: "",
  });

  const [signatureImage, setSignatureImage] = useState(null);

  const signatureImageSave = () => {
    setSignatureImage(
      signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  const updateExtraCheckboxes = ({ name, index, value }) => {
    let newArray = extraDetails[name];
    newArray[index] = value;
    setExtraDetails((prev) => ({ ...prev, [name]: newArray }));
  };
  const updateExtraText = ({ name, index, value }) => {
    let newArray = extraDetails[name];

    newArray[index] = value;
    setExtraDetails((prev) => ({ ...prev, [name]: newArray }));
  };

  const updateFrom = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const updateSender = (e, shipmentDetails) => {
    let { name, value } = e.target;
    if (!shipmentDetails) {
      setSenderAddress((prev) => ({ ...prev, [name]: value }));
    } else if (shipmentDetails) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const updateReceiver = (e, shipmentDetails) => {
    let { name, value } = e.target;
    if (!shipmentDetails) {
      setReceiverAddress((prev) => ({ ...prev, [name]: value }));
    } else if (shipmentDetails) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const uploadFile = async () => {
    // let submittedData = await databases.createDocument('6410df96033a18e6bf84', '6410dfdcda658e4b78f6', uuid(), formData);
    let sender,
      receiver,
      form,
      file1upload,
      file2upload,
      signatureAdded = false;

    const [validSenderAddress, errorInSenderAdd] =
      await tryCatchValidateAddress(senderAddress);
    if (validSenderAddress) {
      sender = true;
    } else if (errorInSenderAdd) {
      dispatch(modal({ message: errorInSenderAdd }));
      return;
    }

    const [validReceiverAddress, errorInReceiverAdd] =
      await tryCatchValidateAddress(receiverAddress);
    if (validReceiverAddress) {
      receiver = true;
    } else if (errorInReceiverAdd) {
      dispatch(modal({ message: errorInReceiverAdd }));
      return;
    }

    const [validForm, errorInForm] = await tryCatchValidateFrom({
      ...formData,
      ...extraDetails,
    });
    if (validForm) {
      form = true;
    } else {
      dispatch(modal({ message: errorInForm }));
      return;
    }
    if (file1) {
      file1upload = true;
    } else {
      dispatch(modal({ message: "Passport Upload Mandatory" }));
      return;
    }
    if (file2) {
      file2upload = true;
    } else {
      dispatch(modal({ message: "Photo Upload Mandatory" }));
      return;
    }

    if (signatureImage) {
      signatureAdded = true;
    } else {
      dispatch(modal({ message: "Signature is Mandatory" }));
      return;
    }

    dispatch(loadingTrue());

    if (sender && receiver && form) {
      try {
        let senderCheck,
          receiverCheck,
          formCheck,
          extraFormCheck,
          fileStatus,
          fileStatus2,
          signatureStatus = null;
        senderCheck = await uploadAddress(senderAddress, senderAddressID);
        receiverCheck = await uploadAddress(receiverAddress, receiverAddressID);
        if (receiverCheck && senderCheck) {
          if (signatureAdded) {
            signatureStatus = await uploadSignature(
              signatureImage,
              signatureID
            );
          }
          if (file1) {
            fileStatus = await uploadDocuments(file1, document1ID);
          } else {
            setFormData((prev) => ({ ...prev, document1: null }));
          }
          if (file2) {
            fileStatus2 = await uploadDocuments(file2, document2ID);
          } else {
            setFormData((prev) => ({ ...prev, document2: null }));
          }
          formCheck = await uploadShipmentForm(formData, uniqueId);
          if (formCheck["$id"]) {
            extraFormCheck = await uploadShipmentExtraForm(
              extraDetails,
              shipmentExtraID
            );

            let history = await updateShipmentHistory(uuid(), {
              status: "STARTED",
              userName: userName,
              userID: userAuthID,
              shipmentId: shipmentID,
            });

            Promise.all([
              formCheck,
              fileStatus,
              fileStatus2,
              extraFormCheck,
              signatureStatus,
              history,
            ]).then(async (res) => {
              setOpenSuccessModal(true);
              const PDFShipment = await generatePDFShipmentFunction(
                shipmentID,
                {
                  formData: { ...formData, startDate: toDayDate },
                  senderAddress,
                  receiverAddress,
                  extraDetails,
                }
              );
              const PDFSaveREsp = await generatePDFFunction(shipmentID, {
                formData: { ...formData, startDate: toDayDate },
                senderAddress,
                receiverAddress,
                extraDetails,
              });
              Promise.all([PDFShipment, PDFSaveREsp]).then((res) => {
                setOpenSuccessModal(false);
                dispatch(loadingFalse());
                navigate(`/view-order/${shipmentID}`);
              });
            });
          } else {
            dispatch(modal({ message: "Error in Data, Check Again" }));
          }
        }
      } catch (er) {
        dispatch(modal({ message: er.toString() }));
      } finally {
        dispatch(loadingFalse());
      }
    }
  };

  // React.useEffect(() => {
  //   if (openSuccessModal) {
  //     timerRef.current = setInterval(() => {
  //       setOpenSuccessModal(false);
  //       dispatch(loadingFalse());
  //       navigate(`/view-order/${shipmentID}`);
  //     }, 10000);
  //   }
  //   // "timer" will be undefined again after the next re-render
  //   return () => clearTimeout(timerRef.current);
  // }, [openSuccessModal]);
  return (
    <div className={classes.main}>
      <Title>Create Form</Title>
      <Flex
        // className={classes.generalDetails}
        gap="xl"
        justify="center"
        align="center"
        direction={{ base: "column", sm: "row" }}>
        {/* Unique Id and Date */}
        <TextInput
          label="id"
          name="shipmentID"
          value={formData.shipmentID}
          disabled
          onChange={updateFrom}
          size="xs"
        />
        <TextInput
          size="xs"
          label="Date"
          name="date"
          value={toDayDate}
          disabled
        />
        <TextInput
          size="xs"
          label="Tag"
          name="tag"
          value={formData.tag}
          onChange={updateFrom}
        />
      </Flex>
      <Flex
        className={classes.userDetails}
        mih={50}
        gap="xl"
        justify="center"
        align="center"
        direction="row"
        wrap="wrap">
        {/* Sender Address */}
        <Paper shadow="md" className={classes.subPaper} withBorder={true}>
          <div className={classes.senderDetails}>
            <Title order={5}>PENGIRIM Address</Title>
            {senderDetails.map((item) => (
              <TextInput
                width="auto"
                key={item.name}
                value={
                  item.shipment ? formData[item.name] : senderAddress[item.name]
                }
                label={item.label}
                name={item.name}
                onChange={(e) => updateSender(e, item.shipment)}
                size="xs"
              />
            ))}
          </div>
        </Paper>
        {/* Receiver Address */}
        <Paper shadow="md" className={classes.subPaper} withBorder={true}>
          <div className={classes.senderDetails}>
            <Title order={5}>PENERIMA Address</Title>
            {receiverDetails.map((item) => (
              <TextInput
                label={item.label}
                key={item.name}
                name={item.name}
                value={
                  item.shipment
                    ? formData[item.name]
                    : receiverAddress[item.name]
                }
                onChange={(e) => updateReceiver(e, item.shipment)}
                size="xs"
              />
            ))}
          </div>
        </Paper>
      </Flex>
      {/* Product Data */}
      <Paper shadow="md" className={classes.productDetails} withBorder={true}>
        <Title>Product Data</Title>
        {/* Size Details */}
        <div className={classes.submitButton}>
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap="md"
            align={{ base: "center", sm: "flex-end" }}
            justify="space-around"
            w="100%">
            <Select
              className={classes.productDetailsSelect}
              label="Size"
              value={extraDetails.size}
              onChange={(e) =>
                setExtraDetails((prev) => ({ ...prev, size: e }))
              }
              data={sizeDropDown}
            />
            <Checkbox
              value={extraDetails.extra}
              label="Extra"
              onChange={(e) =>
                setExtraDetails((prev) => ({
                  ...prev,
                  extra: e.target.checked,
                }))
              }
            />
            <Checkbox
              value={extraDetails.packing}
              label="Packing Kayu"
              onChange={(e) =>
                setExtraDetails((prev) => ({
                  ...prev,
                  packing: e.target.checked,
                }))
              }
            />
            <Checkbox
              value={extraDetails.insurance}
              label="Insurans"
              onChange={(e) =>
                setExtraDetails((prev) => ({
                  ...prev,
                  insurance: e.target.checked,
                }))
              }
            />
            <Checkbox
              value={extraDetails.wrapping}
              label="Wrapping"
              onChange={(e) =>
                setExtraDetails((prev) => ({
                  ...prev,
                  wrapping: e.target.checked,
                }))
              }
            />
          </Flex>
        </div>
        {/* Separation of Areas */}
        <Flex className={classes.productSets} direction="row">
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Makanan</Title>
            <Flex rowGap={"lg"} direction="column">
              {makananDD.map((item, index) => (
                <>
                  <Checkbox
                    label={item.label}
                    key={item.name}
                    onChange={(e) =>
                      updateExtraCheckboxes({
                        name: "makanan",
                        index: index,
                        value: e.target.checked,
                      })
                    }
                    value={extraDetails.makanan[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.makanan[index] ? (
                    <TextInput
                      placeholder={item.label}
                      value={extraDetails.makananArrayStr[index]}
                      autoFocus
                      onChange={(e) =>
                        updateExtraText({
                          name: "makananArrayStr",
                          index: index,
                          value: e.target.value,
                        })
                      }
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              <OtherExtraFields
                shiftIndex={7}
                updateTxt={(value, index) =>
                  updateExtraText({
                    name: "makananArrayStr",
                    index: index,
                    value: value,
                  })
                }
                valueSet={extraDetails.makananArrayStr}
              />
            </Flex>
          </Paper>
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Pakaian</Title>
            <Flex rowGap={"lg"} direction="column">
              {pakaianDD.map((item, index) => (
                <>
                  <Checkbox
                    label={item.label}
                    key={item.name}
                    onChange={(e) =>
                      updateExtraCheckboxes({
                        name: "pakaian",
                        index: index,
                        value: e.target.checked,
                      })
                    }
                    value={extraDetails.pakaian[index]}
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.pakaian[index] ? (
                    <TextInput
                      placeholder={item.label}
                      autoFocus
                      value={extraDetails.pakaianArrayStr[index]}
                      onChange={(e) =>
                        updateExtraText({
                          name: "pakaianArrayStr",
                          index: index,
                          value: e.target.value,
                        })
                      }
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              <OtherExtraFields
                shiftIndex={7}
                updateTxt={(value, index) =>
                  updateExtraText({
                    name: "pakaianArrayStr",
                    index: index,
                    value: value,
                  })
                }
                valueSet={extraDetails.pakaianArrayStr}
              />
            </Flex>
          </Paper>
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Alat Dupar</Title>
            <Flex rowGap={"lg"} direction="column">
              {alatDapurDD.map((item, index) => (
                <>
                  <Checkbox
                    label={item.label}
                    key={item.name}
                    value={extraDetails.alatDupar[index]}
                    onChange={(e) =>
                      updateExtraCheckboxes({
                        name: "alatDupar",
                        index: index,
                        value: e.target.checked,
                      })
                    }
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.alatDupar[index] ? (
                    <TextInput
                      value={extraDetails.alatDuparArrayStr[index]}
                      autoFocus
                      onChange={(e) =>
                        updateExtraText({
                          name: "alatDuparArrayStr",
                          index: index,
                          value: e.target.value,
                        })
                      }
                      placeholder={item.label}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              <OtherExtraFields
                shiftIndex={7}
                updateTxt={(value, index) =>
                  updateExtraText({
                    name: "alatDuparArrayStr",
                    index: index,
                    value: value,
                  })
                }
                valueSet={extraDetails.alatDuparArrayStr}
              />
            </Flex>
          </Paper>
        </Flex>
        <Flex className={classes.productSets} direction="row">
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Barang Rumah</Title>
            <Flex rowGap={"lg"} direction="column">
              {barangRumah.map((item, index) => (
                <>
                  <Checkbox
                    label={item.label}
                    key={item.name}
                    value={extraDetails.barang[index]}
                    onChange={(e) =>
                      updateExtraCheckboxes({
                        name: "barang",
                        index: index,
                        value: e.target.checked,
                      })
                    }
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.barang[index] ? (
                    <TextInput
                      autoFocus
                      onChange={(e) =>
                        updateExtraText({
                          name: "barangArrayStr",
                          index: index,
                          value: e.target.value,
                        })
                      }
                      value={extraDetails.barangArrayStr[index]}
                      placeholder={item.label}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              <OtherExtraFields
                shiftIndex={7}
                updateTxt={(value, index) =>
                  updateExtraText({
                    name: "barangArrayStr",
                    index: index,
                    value: value,
                  })
                }
                valueSet={extraDetails.barangArrayStr}
              />
            </Flex>
          </Paper>
          <Paper shadow="sm" withBorder={true} className={classes.productSet}>
            <Title order={5}>Elektronik</Title>
            <Flex rowGap={"lg"} direction="column">
              {elektronik.map((item, index) => (
                <>
                  <Checkbox
                    label={item.label}
                    key={item.name}
                    value={extraDetails.elektronic[index]}
                    onChange={(e) =>
                      updateExtraCheckboxes({
                        name: "elektronic",
                        index: index,
                        value: e.target.checked,
                      })
                    }
                    name={item.name}
                    size="sm"
                  />
                  {extraDetails.elektronic[index] ? (
                    <TextInput
                      autoFocus
                      onChange={(e) =>
                        updateExtraText({
                          name: "elektronikArrayStr",
                          index: index,
                          value: e.target.value,
                        })
                      }
                      value={extraDetails.elektronikArrayStr[index]}
                      placeholder={item.label}
                      withAsterisk
                    />
                  ) : null}
                </>
              ))}
              <OtherExtraFields
                shiftIndex={7}
                updateTxt={(value, index) =>
                  updateExtraText({
                    name: "elektronikArrayStr",
                    index: index,
                    value: value,
                  })
                }
                valueSet={extraDetails.elektronikArrayStr}
              />
            </Flex>
          </Paper>
        </Flex>

        <Flex className={classes.uploadButtons}>
          <Flex direction="column" rowGap="md">
            <TextInput
              size="xs"
              label="Amount Paid"
              name="amount"
              value={formData.amount}
              onChange={updateFrom}
            />
          </Flex>
        </Flex>

        <Flex className={classes.uploadButtons}>
          {/* Signature Component */}
          <Button onClick={open}>Signature</Button>
          {signatureImage !== null && (
            <img src={signatureImage} alt="Signature" />
          )}
          {/* Upload Component */}
          <Flex direction="column" rowGap="md">
            {/* <FileInput value={file1} onChange={setFile1} /> */}
            <FileButton onChange={setFile1}>
              {(props) => <Button {...props}>Upload Customer Passport</Button>}
            </FileButton>
            {file1 && <Text size={"sm"}>{file1.name}</Text>}
            <FileButton onChange={setFile2} accept="image/png,image/jpeg">
              {(props) => <Button {...props}>Upload Customer Photo</Button>}
            </FileButton>
            {file2 && <Text size={"sm"}>{file2.name}</Text>}
          </Flex>
        </Flex>
      </Paper>
      <Flex className={classes.submitButton} rowGap="md">
        <Checkbox
          value={formData.terms}
          label="TERMS and CONDITIONS"
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, terms: e.target.checked }))
          }></Checkbox>

        <Button onClick={uploadFile}>Submit</Button>
      </Flex>
      <Modal
        opened={opened}
        onClose={(e) => {
          close(e);
          signatureImageSave(e);
        }}
        size="sm"
        title="Sign here please">
        <SignaturePad
          canvasProps={{
            className: "signatureCanvas",
          }}
          ref={signatureRef}
        />
      </Modal>
      <Modal
        // aut
        // onFocus={true}
        opened={openSuccessModal}
        onClose={(e) => {
          // navigate(`/view-order/${shipmentID}`);
        }}
        size={"xs"}
        withCloseButton={false}>
        <p>Successfull Saved the Order </p>
        <p>Please Wait Reciept is Generating...</p>
      </Modal>
    </div>
  );
}

export default CreateOrder;
