import { b64toFile } from "../components/utils";
import {
  loadingFalse,
  loadingTrue,
  login,
  resetPasswordChange,
  setAdmin,
} from "../redux/userAuth";
import {
  account,
  databases,
  functions,
  storage,
  teams,
} from "./appwriteConfig";

import { Query } from "appwrite";

export const uploadAddress = async (address, uniqueID) => {
  try {
    let response = await databases.createDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ADDRESS_ID,
      uniqueID,
      address
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const uploadDocuments = async (file, uniqueID) => {
  try {
    let response = await storage.createFile(
      process.env.REACT_APP_DOCUMENTS_BUCKET_ID,
      uniqueID,
      file
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const uploadDeliveryDocuments = async (file, uniqueID) => {
  try {
    let response = await storage.createFile(
      process.env.REACT_APP_DELIVERY_BUCKET_ID,
      uniqueID,
      file
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const uploadSignature = async (file, uniqueID) => {
  try {
    let blob = await b64toFile(file, uniqueID);
    // blob.lastModified= toDateString(new Date())
    // blob.name = uniqueID
    // blob.webkitRelativePath = ''
    let response = await storage.createFile(
      process.env.REACT_APP_SIGNATURE_BUCKET_ID,
      uniqueID,
      blob
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const uploadShipmentForm = async (form, uniqueID) => {
  try {
    let response = await databases.createDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ID,
      uniqueID,
      form
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateShipmentForm = async (form, uniqueID) => {
  try {
    let response = await databases.updateDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ID,
      uniqueID,
      form
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const uploadShipmentExtraForm = async (extraForm, uniqueID) => {
  try {
    let response = await databases.createDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_EXTRA_ID,
      uniqueID,
      extraForm
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getShipmentList = async (userID, isAdmin, page = 0) => {
  let queryStack = [
    Query.orderDesc("$createdAt"),
    Query.limit(15),
    Query.offset(15 * page),
  ];
  if (!isAdmin) {
    queryStack.push(Query.equal("userID", userID));
  }
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ID,
      [...queryStack]
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getShipmentListFilter = async (
  userID,
  isAdmin,
  data = {
    fromDate: null,
    toDate: new Date(),
    Id: "",
    tag: "",
    status: "",
    userID: "",
  }
) => {
  let queryStack = [Query.orderDesc("$createdAt"), Query.limit(1000)];
  if (!isAdmin) {
    queryStack.push(Query.equal("userID", userID));
  }
  if (data.fromDate && data.toDate) {
    queryStack.push(Query.greaterThanEqual("$createdAt", data.fromDate));
    queryStack.push(Query.lessThanEqual("$createdAt", data.toDate));
  }
  if (data.Id) {
    queryStack.push(Query.equal("shipmentID", data.Id));
  }
  if (data.tag) {
    queryStack.push(Query.equal("tag", data.Id));
  }
  if (data.status) {
    queryStack.push(Query.equal("status", data.status));
  }
  if (data.userID) {
    queryStack.push(Query.equal("userID", data.userID));
  }
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ID,
      [...queryStack]
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getListOfExtraDetails = async (list = []) => {
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_EXTRA_ID,
      [Query.equal("shipmentID", [...list]), Query.limit(1000)]
    );
    return resp.documents;
  } catch (er) {
    console.log(er);
  }
};

export const getListOfAddressDetailsByListOfIDS = async (list = []) => {
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ADDRESS_ID,
      [Query.equal("$id", [...list]), Query.limit(1000)]
    );
    return resp.documents;
  } catch (er) {
    console.log(er);
  }
};

export const getShipmentItem = async (itemID) => {
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_ID,
      [Query.equal("shipmentID", itemID.toString())]
    );
    return resp?.documents[0];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getShipmentExtraItem = async (itemID) => {
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_EXTRA_ID,
      [Query.equal("shipmentID", itemID.toString())]
    );
    return resp?.documents[0];
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getShipmentHistory = async (itemID) => {
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_HISTORY_ID,
      [Query.equal("shipmentId", itemID.toString())]
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getShipmentPDF = async (itemID) => {
  try {
    let resp = storage.getFileView(process.env.REACT_APP_PDF_BUCKET_ID, itemID);
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getShipmentPDFAWB = async (itemID) => {
  try {
    let resp = storage.getFileView(
      process.env.REACT_APP_PDF_AWB_BUCKET_ID,
      itemID
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getShipmentDocument = async (itemID) => {
  try {
    let resp = storage.getFileView(
      process.env.REACT_APP_DOCUMENTS_BUCKET_ID,
      itemID
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getShipmentDeliveryDocument = async (itemID) => {
  try {
    let resp = storage.getFileView(
      process.env.REACT_APP_DELIVERY_BUCKET_ID,
      itemID
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateShipmentHistory = async (documentID, data) => {
  try {
    let resp = await databases.createDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_SHIPMENT_HISTORY_ID,
      documentID,
      data
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updatePasswordOfUser = async (newPass, oldPass) => {
  try {
    let resp = await account.updatePassword(newPass, oldPass);
    let resp2 = await UpdateUserEmailVerification(resp.$id, true);
    if (resp2) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUserList = async () => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_FUNCTION_ID,
      "getUserList"
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const UpdateUserStatus = async (ID = "", status = false) => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_FUNCTION_ID,
      "UpdateUserStatus|" + ID + "|" + status
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CreateNewUser = async (name, email, password) => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_FUNCTION_ID,
      "CreateNewUser" + "|" + name + "|" + email + "|" + password
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const UpdateUserEmailVerification = async (ID, status) => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_FUNCTION_ID,
      "UpdateEmailVerification|" + ID + "|" + status
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const UpdateUserAdminStatus = async (ID, status) => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_FUNCTION_ID,
      "UpdateUserAdminStatus|" + ID + "|" + status
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getUserMembershipDetails = (ID) => async (dispatch) => {
  try {
    await teams
      .listMemberships(process.env.REACT_APP_PDF_TEAM_ID, [
        Query.equal("userId", ID),
      ])
      .then((res) => {
        if (res.total) {
          dispatch(setAdmin(true));
        }
      });
    return true;
  } catch (err) {
    console.log(err, "the Teams Error");
    dispatch(setAdmin(false));
    return false;
  }
};

export const generatePDFFunction = async (uniqueID, data) => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_PDF_FUNCTION_ID,
      uniqueID + "|" + JSON.stringify(data)
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const generatePDFShipmentFunction = async (uniqueID, data) => {
  try {
    let resp = await functions.createExecution(
      process.env.REACT_APP_PDF_SHIPMENT_FUNCTION_ID,
      uniqueID + "|" + JSON.stringify(data)
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getUser = (account) => async (dispatch) => {
  dispatch(loadingTrue());
  try {
    let user = await account.get();
    dispatch(login({ userEmail: user.email, userID: user["$id"], user: user }));
    if (!user.emailVerification) {
      dispatch(resetPasswordChange(true));
    }
    if (user.labels.include("admin")) {
      dispatch(setAdmin(true));
    } else {
      dispatch(setAdmin(false));
    }
  } catch (er) {
    console.log(er);
  } finally {
    dispatch(loadingFalse());
  }
};

export const getStatusList = async () => {
  // let newQ = isAdmin ? [] : [Query.equal("userID", userID)];
  try {
    let resp = await databases.listDocuments(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_LOCATION_ID,
      [Query.limit(40)]
    );
    return resp;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// export const updateLabelOfUser = (ID) => async (dispatch)=>{
//     try{
// let resp = await
//     }catch(er){

//     }finally{

//     }
// }
